import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const H1 = styled.h1(({ theme, fontSize }) => ({
  lineHeight: '47px',
  color: `${theme.colors.primary}`,
  fontSize: `${theme?.functions?.toEm(fontSize)}}`,
}));

H1.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.object,
  fontSize: PropTypes.number,
};

export default H1;
