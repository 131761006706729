import styled from '@emotion/styled';

const Contain = styled.div(props => ({
  display: props.display ? props.display : 'flex',
  ...props.position && { position: props.position },
  ...props.top && { top: props.top },
  ...props.bottom && { bottom: props.bottom },
  ...props.direction && { flexDirection: props.direction },
  ...props.align && { alignItems: props.align },
  ...props.justify && { justifyContent: props.justify },
  ...props.width && { width: props.width },
  ...props.maxWidth && { maxWidth: props.maxWidth },
  ...props.minHeight && { minHeight: props.minHeight },
  ...props.height && { height: props.height },
  ...props.padding && { padding: props.padding },
  ...props.paddingBottom && { paddingBottom: props.paddingBottom },
  ...props.paddingTop && { paddingTop: props.paddingTop },
  ...props.margin && { margin: props.margin },
  ...props.border && { border: `1px solid ${props.theme.colors.turquoise}` },
  ...props.borderRadius && { borderRadius: props.borderRadius },
  ...props.borderTop && { borderTop: props.borderTop },
  ...props.background && { background: props.background },
  ...props.flexFlow && { flexFlow: props.flexFlow },
  ...props.paddingLeft && {
    '@media (max-width: 1040px)': {
      paddingLeft: 'unset',
    },
  },
}));

export default Contain;