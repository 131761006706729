import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import P from './P';

const Label = styled.label({
  position: 'absolute',
  top: '2px',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '12px',
  userSelect: 'none',
  cursor: 'text',
  width: 'max-content',
});

const InputContainer = styled.div(({ margin, maxWidth }) => ({
  position: 'relative',
  width: maxWidth,
  ...margin && { margin: margin },
  textAlign: 'center',
}));

const InputField = styled.input(({ height, width, padding, disabled, borderColor, justify, display, invalid, theme }) => ({
  appearance: 'textfield',
  fontSize: theme.functions.toEm(14),
  fontWeight: 400,
  lineHeight: '18.23px',
  width: '100%',
  height: height ? height : '40px',
  ...width && {
    width: width,
  },
  padding: padding ? padding : '0 14px',
  border: disabled ? 0 : `1px solid ${borderColor ? borderColor : '#3ab86c'}`,
  borderRadius: '4px',
  transition: 'all .2s ease-out',
  ...disabled && {
    background: theme.colors.disabledBlue,
  },
  ...justify && {
    justifyContent: justify,
  },
  ...display && {
    display: display,
  },
  ...invalid && {
    border: `1px solid ${theme.colors.red}`,
  },
  '&:hover': {
    border: disabled ? 0 : `1px solid ${theme.colors.primary}`,
  },
  '::placeholder': {
    opacity: 1,
  },
  '&:focus': {
    outline: `1px solid ${theme.colors.primary}`,
  }
}));

const Input = (props) => {
  const { label, width, margin, errors, touched, name, maxWidth } = props;
  return (
    <InputContainer margin={margin} width={width} maxWidth={maxWidth} >
      <Label>{label}</Label>
      <InputField {...props} />

      {/* Validation text */}
      {errors && errors[name] && touched && touched[name] && (
        <P margin={'3px 0 0'} fontSize={12}>{errors[name]}</P>
      )}
    </InputContainer>
  );
};

Input.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default Input;