import styled from '@emotion/styled';

const Button = styled.button(({ theme, display, maxWidth, direction, minHeight, width, minWidth, margin, padding, height, fontSize, borderRadius, outline, shadowColour, border, noBorder, disabled, xSmall, activeHover }) => ({
  display: display || 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...maxWidth && { maxWidth: maxWidth },
  ...direction && { flexDirection: direction },
  ...minWidth && { minWidth: minWidth },
  ...width && { width: width },
  ...minHeight && { minHeight: minHeight },
  ...margin && { margin: margin },
  ...padding && { padding: padding },
  ...height && { height: height },
  height: height ? height : '50px',
  fontSize: fontSize ? theme.functions.toEm(fontSize) : theme.functions.toEm(16),
  lineHeight: '12px',
  fontWeight: '400',
  borderRadius: borderRadius ? borderRadius : '4px',
  userSelect: 'none',
  cursor: 'pointer',
  background: outline ? '#fff' : theme.colors.primary,
  color: outline ? theme.colors.primary : '#fff',
  transition: 'all 0.3s ease-out',

  ...!shadowColour && {
    border: border ? border : 0,
  },
  ...outline && {
    border: `2px solid ${shadowColour ? shadowColour : theme.colors.primary}`,
  },
  ...!noBorder && {
    boxShadow: `inset 0 0 0 2px ${border ? theme.colors?.[border] : theme.colors?.primary}`,
  },
  '&:hover': {
    ...!disabled && {
      ...!noBorder && !outline && { background: '#193642' },
      ...noBorder && activeHover ? {
        boxShadow: `inset 0 0 0 ${outline ? xSmall ? '1px' : '2px' : '3px'} ${outline && shadowColour ? shadowColour : outline && !shadowColour ? theme.colors.primary : theme.colors.secondary}`,
        a: {
          transition: 'all 0.5s ease-out',
          color: outline ? theme.colors.primary : theme.colors.secondary,
        },
      } : !noBorder && {
        boxShadow: `inset 0 0 0 ${outline ? xSmall ? '1px' : '2px' : '3px'} ${outline && shadowColour ? shadowColour : outline && !shadowColour ? theme.colors.primary : theme.colors.secondary}`,
        a: {
          transition: 'all 0.5s ease-out',
          color: outline ? theme.colors.primary : theme.colors.secondary,
        },
      },
    },
  },
  ...disabled && {
    background: 'rgb(193, 230, 229) none repeat scroll 0% 0%',
    boxShadow: 'none !important',
    cursor: 'not-allowed',
    color: `${theme.colors.white}`,
    ...outline && {
      border: 'unset',
    },
  },
}));

export default Button;