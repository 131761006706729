import React from 'react';
import styled from '@emotion/styled';
import P from './P';

const NoDataWrap = styled.div({
  height: '300px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const NoData = () => {
  return (
    <NoDataWrap>
      <P>There is no data to display</P>
    </NoDataWrap>
  );
};

export default NoData;