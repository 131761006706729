import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from './components/Header';
import { Main } from './styles';
import { getAccountInfo } from '../../components/Layout/reducers/session';
import LoadingSpinner from '../LoadingSpinner';

const Layout = ({ Component, isPrivate, pageName }) => {
  const noHeaderPages = ['Login'];
  const coloredHeader = ['Home', 'User Details', 'Trade Details'];

  const { accountInfo } = useSelector(state => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    const onMount = async () => {
      try {
        dispatch(getAccountInfo());
      } catch (error) {
        console.log(error);
      }
    };
    if (accountInfo === null) {
      onMount();
    }
  }, []);

  if (accountInfo === null) {
    return (<LoadingSpinner />);
  }

  if (!accountInfo?.id && isPrivate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <>
      {!noHeaderPages.includes(pageName) && (
        <Header coloredHeader={coloredHeader.includes(pageName) ? true : false} />
      )}

      <Main paddingTop={!noHeaderPages.includes(pageName) && true}>
        <Component />
      </Main>
    </>
  );
};

Layout.propTypes = {
  Component: PropTypes.func,
  isPrivate: PropTypes.bool,
  pageName: PropTypes.string,
};

export default Layout;