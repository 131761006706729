import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Contain from '../../Contain';
import P from '../../P';
import { NavigationLinks, Item, } from './styles';
import Logout from '../../../assets/images/logout.png';
import PropTypes from 'prop-types';


const NavLinks = ({ mobile, handleLogout }) => {
  const router = useLocation();
  const { pathname } = router;

  const navigationLinks = [
    { label: 'Home', path: '/', },
    { label: 'Users', path: '/users' },
    { label: 'Trades Management', path: '/trades-management' },
    { label: 'Payments', path: '/payments' },

  ];

  mobile && navigationLinks.push({ label: 'Settings', path: '/settings' }, { label: 'Log out', path: '' });

  return (
    <NavigationLinks mobile={mobile ? true : false}>
      {navigationLinks.map(({ label, path }) => (
        <Item key={label}>
          <Link
            className={path === '/' + pathname?.split('/')[1] && label !== 'Log out' ? 'active' : undefined}
            to={path}>
            <Contain>
              <P onClick={() => label === 'Log out' && handleLogout()} color={label === 'Log out' ? 'red' : mobile ? 'white' : '#10242C'} margin={'0 5px 0 0'} fontWeight={'700'}>{label}</P> {label === 'Log out' && <img src={Logout} alt='Log out' />}
            </Contain>
          </Link>
        </Item>
      ))}
    </NavigationLinks>
  );
};

NavLinks.propTypes = {
  mobile: PropTypes.bool,
  handleLogout: PropTypes.func,
};

export default NavLinks;