import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const colourStyles = ({ searchField, width, height }) => ({
  container: styles => ({
    ...styles,
    maxWidth: width ? width : '100%',
    width: '100%',
    lineHeight: 1,
  }),
  control: (styles) => ({
    ...styles,
    ...height && { height: height },
    cursor: 'pointer',
    padding: '4px 10px',
    border: '1px solid #3ab86c',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#EAF6F6',
      color: 'black',
    },
    ...isSelected && {
      background: 'white',
      color: 'black',
    },
    cursor: 'pointer',
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    fontSize: (searchField ? '18px' : '14px'),
    ...isDisabled && {
      color: 'rgb(179, 179, 179)',
    },
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
});

const Label = styled.label({
  fontWeight: '700'
});
const Container = styled.div(({ height, width, margin }) => ({
  height: height ? height : 'max-content',
  width: width ? width : '100%',
  ...margin && { margin: margin },
  zIndex: '3',
}));

const SelectComponent = (props) => {
  const { handler, label, width, margin, defaultValue } = props;
  return (
    <Container width={width} margin={margin}>
      {label && <Label>{label}</Label>}
      <Select defaultValue={defaultValue} {...props} onChange={handler} styles={colourStyles({ ...props })} />
    </Container>
  );
};

SelectComponent.propTypes = {
  handler: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  defaultValue: PropTypes.object,
};

export default SelectComponent;