import styled from '@emotion/styled';

const P = styled.p(({ theme, fontSize, margin, textAlign, fontWeight, color, pointer, width, hover }) => ({
  fontSize: fontSize ? theme.functions.toEm(fontSize) : theme.functions.toEm(14),
  ...textAlign && { textAlign: textAlign },
  ...pointer && { cursor: 'pointer' },
  ...margin && { margin: margin },
  ...width && { width: width },
  ...fontWeight && { fontWeight: fontWeight },
  color: color ? color : theme.colors.primary,

  ...hover && {
    ':hover': {
      color: theme.colors.turquoise,
    }
  }
}));

export default P;