import styled from '@emotion/styled';

export const Header = styled.div(({ theme }) => ({
  width: '100%',
  background: theme.colors.disabledBlue,
  paddingBottom: '40px',
}));

export const DataFooter = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 1200px)': {
    height: 'unset',
    margin: '10px 0 0 0',
  },
  '@media (max-width: 1040px)': {
    flexDirection: 'column',
  },
});

export const DataColumn = styled.div(({ padding, theme, direction, height, align, fullSize, border, justify, width, noMargin, hover, noRadius }) => ({
  display: 'flex',
  flexDirection: direction ? direction : 'column',
  height: height || '222px',
  background: '#fff',
  borderRadius: noRadius ? '0' : '10px',
  padding: padding ? padding : fullSize ? '40px 40px' : '35px  40px 30px',
  ...(align && { alignItems: align }),
  ...(border && { border: border }),
  ...(justify && { justifyContent: justify }),
  ...(width && { width: width }),

  ...(noMargin && {
    height: '152px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  }),

  ...(hover && {
    '&:hover': {
      transition: '.15s ease-in',
      border: `1px solid ${theme.colors.turquoise}`,
      boxShadow: '6px 6px 38px -6px rgba(193,230,229,0.75)',
    },
  }),

  '@media (max-width: 1040px)': {
    width: '100%',
    flexDirection: 'column',
    ...(!noMargin && {
      margin: '10px 0',
    }),
    ...(noMargin && {
      height: 'unset',
      alignItems: 'unset',
      flexDirection: 'column',
      button: {
        width: 'max-content',
      },
    }),
  },
}));
