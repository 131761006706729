import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch } from '../../components/common/http';

// Actions
export const fetchUsers = createAsyncThunk('users/fetchUsers', async (filters) => {
  const filterParams = new URLSearchParams(Object.assign({}, filters)).toString();
  const data = await get(`/users?${filterParams}`);
  return data;
});

export const blockUser = createAsyncThunk('users/blockUser', async (id) => {
  const data = await patch(`/users/${id}/block`);
  return data;
});

export const unblockUser = createAsyncThunk('users/unblockUser', async (id) => {
  const data = await patch(`/users/${id}/unblock`);
  return data;
});

// Slice
const slice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    isLoading: false,
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.status = 'loading';
      state.isLoadin = true;
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
      state.isLoadin = false;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.isLoadin = false;
    },
  }
});

export default slice.reducer;