import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Divide as Hamburger } from 'hamburger-react';
import PropTypes from 'prop-types';
import LogoSVG from '../../../assets/images/Logo.svg';
import Logout from '../../../assets/images/logout.png';
import P from '../../P';
import { HeaderStyle, Logo, Avatar, Dropdown, DropdownItem, MobileMenu, BurgerToggle } from './styles';
import ToggleVisible from '../../../components/common/ToggleVisible';
import { logout, getAccountInfo } from '../../Layout/reducers/session';
import NavLinks from './NavLinks';

const Header = ({ coloredHeader }) => {
  const [burgerMenu, setBurgerMenu] = useState(false);
  const { accountInfo } = useSelector(state => state.session);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  const dispatch = useDispatch();

  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(getAccountInfo());
  };

  return (
    <HeaderStyle coloredHeader={coloredHeader}>
      <MobileMenu open={burgerMenu}>
        <Link to='/'>
          <Logo src={LogoSVG} alt='logo' />
        </Link>
        <NavLinks handleLogout={handleLogout} mobile />
      </MobileMenu>

      <Link to='/'>
        <Logo src={LogoSVG} alt='logo' />
      </Link>

      <NavLinks />

      <BurgerToggle>
        <Hamburger color="#3ab86c" toggled={burgerMenu} toggle={setBurgerMenu} />
      </BurgerToggle>

      <Avatar onClick={() => setIsComponentVisible(!isComponentVisible)}>
        <P fontSize={18} fontWeight={700}>{accountInfo && accountInfo.email[0].toUpperCase()}</P>
      </Avatar>

      {
        isComponentVisible && (
          <Dropdown ref={ref}>
            <DropdownItem><Link to='/settings'><P fontWeight={700}>Settings</P></Link></DropdownItem>
            <DropdownItem onClick={() => handleLogout()}><P fontWeight={700} margin={'0 5px 0 0'} color={'red'} >Log Out</P><img src={Logout} alt='Log out' /></DropdownItem>
          </Dropdown>
        )
      }
    </HeaderStyle>
  );
};

Header.propTypes = {
  coloredHeader: PropTypes.bool,
};

export default Header;