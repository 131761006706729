import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const PaginationStyle = styled.div`
.pagination {
  margin-top:20px;
  display:flex;
  justify-content:center;
  overflow: auto;
  ul {
    display:flex;
    justify-content:space-between;
    list-style:none;
    align-items:center;
    height:44px;
    align-items: center;
    max-width:max-content;
    width:100%;

    li {
      display:flex;
      justify-content:center;
      align-items: center;

      a {
        text-decoration:none;
        cursor:pointer;
        padding: 5px 12px;
      }
    }

    .nextArrow,
    .previous {
      display:flex;
      justify-content:center;
      align-items:center;
      
      &.disabled a {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.2;
      }
    }

    .activePage {
      background:${({ theme }) => theme?.colors?.primary};
      border-radius: 4px;
      display:flex;
      justify-content:center;
      align-items: center;
      
      a {
        color:${({ theme }) => theme?.colors?.white};
      }
    }
  }
}
`;



const Pagination = ({ ...props }) => {
  return (
    <>
      {props.pageCount > 1 &&
        <PaginationStyle>
          <div className="pagination">
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              activeClassName="activePage"
              nextClassName="nextArrow"
              {...props}
            />
          </div>
        </PaginationStyle>
      }
    </>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
};

export default Pagination;