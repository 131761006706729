const theme = {
  functions: {
    toEm: (px) => `${px / 14}em`
  },
  colors: {
    white: '#fff',
    red: '#F42B03',
    turquoise: '#3ab86c',
    primary: '#10242C',
    disabledBlue: '#EAF6F6',
  },
};

export default theme;
