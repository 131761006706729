import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import Contain from '../../components/Contain';
import P from '../../components/P';
import { Header, DataColumn, DataFooter } from '../../components/Collection';
import { fetchTradesDetails } from './reducers';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import NoData from '../../components/NoData';
import moment from 'moment';
import 'rsuite-table/dist/css/rsuite-table.css';

const index = () => {
  const params = useParams();
  const { data } = useSelector(state => state.tradeDetails);
  const { id } = params;
  const { closed, symbol, paused, strategy, history, position, logs } = data;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTradesDetails(id));
  }, []);

  return (
    <>
      <Helmet>
        <title>Trade Details | Leekz</title>
      </Helmet>

      <Contain direction={'row'}>
        <Header>
          <Container>
            <P margin={'20px 0 10px'} fontWeight={700}>Trade info</P>
            <DataFooter>
              <DataColumn
                border='1px solid #EAF6F6'
                hover
                width={'23.5%'}
                justify={'center'}
                align={'center'}>
                <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                  <P fontSize={18} margin={'0 0 15px'}>Closed</P>
                  <P fontSize={28} fontWeight={700}>{closed ? 'Yes' : 'No'}</P>
                </Contain>
              </DataColumn>

              <DataColumn
                border='1px solid #EAF6F6'
                hover
                width={'23.5%'}
                justify={'center'}
                align={'center'}>
                <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                  <P fontSize={18} margin={'0 0 15px'}>Pair</P>
                  <P fontSize={28} fontWeight={700}>{symbol}</P>
                </Contain>
              </DataColumn>

              <DataColumn
                border='1px solid #EAF6F6'
                hover
                width={'23.5%'}
                justify={'center'}
                align={'center'}>
                <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                  <P fontSize={18} margin={'0 0 15px'}>Last action</P>
                  <P fontSize={28} fontWeight={700}>{position?.type} @{position?.price}</P>
                </Contain>
              </DataColumn>

              <DataColumn
                border='1px solid #EAF6F6'
                hover
                width={'23.5%'}
                justify={'center'}
                align={'center'}>
                <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                  <P fontSize={18} margin={'0 0 15px'}>Paused</P>
                  <P fontSize={28} fontWeight={700}>{paused ? 'Yes' : 'No'}</P>
                </Contain>
              </DataColumn>
            </DataFooter>



            {/* Current strategy */}
            <P margin={'20px 0 10px'} fontWeight={700}>Current Strategy</P>
            <Contain>
              <DataFooter>
                <DataColumn noRadius height={'152px'} padding={'0'} justify={'center'} align={'center'} width={'25%'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18}>Name</P>
                    <P margin={'7px 0 0'} fontSize={33}>{strategy?.name}</P>
                  </Contain>
                </DataColumn>
                <DataColumn noRadius width={'25%'} height={'152px'} padding={'0'} justify={'center'} align={'center'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18}>Tolerance</P>
                    <P margin={'7px 0 0'} fontSize={33}>{strategy?.tolerance}</P>
                  </Contain>
                </DataColumn>
                <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18}>Trailing take profit</P>
                    <P margin={'7px 0 0'} fontSize={33}>{strategy?.trailingTakeProfit}</P>
                  </Contain>
                </DataColumn>
                <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18}>Initial bid</P>
                    <P margin={'7px 0 0'} fontSize={33}>{strategy?.initialBid}</P>
                  </Contain>
                </DataColumn>
                <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18}>percentage</P>
                    <P margin={'7px 0 0'} fontSize={33}>{strategy?.percentage}</P>
                  </Contain>
                </DataColumn>
              </DataFooter>
            </Contain>
          </Container>
        </Header>
      </Contain>

      <Container>
        <P margin={'20px 0 10px'} fontWeight={700}>Trades history</P>
        {history?.length ? (
          <Table wordWrap={true} autoHeight rowHeight={70} data={history}>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Type</P></HeaderCell>
              <Cell>{({ type }) => (<P fontSize={'12'}>{type}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Quantity</P></HeaderCell>
              <Cell>{({ quantity }) => (<P fontSize={'12'}>{quantity}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Price</P></HeaderCell>
              <Cell>{({ price }) => (<P fontSize={'12'}>{price}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Transaction fee</P></HeaderCell>
              <Cell>{({ fee }) => (<P fontSize={'12'}>{fee}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Created date</P></HeaderCell>
              <Cell>{({ createdAt }) => (<P fontSize={'12'}>{moment(createdAt).format('ll')}</P>)}</Cell>
            </Column>
          </Table>) : <NoData />}

        <P margin={'20px 0 10px'} fontWeight={700}>Trades logs</P>
        {logs?.length > 0 ? (
          <Table wordWrap={true} autoHeight rowHeight={70} data={logs}>
            <Column flexGrow={2.5}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Message</P></HeaderCell>
              <Cell>{({ message }) => (<P fontSize={'12'}>{message}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Type</P></HeaderCell>
              <Cell>{({ type }) => (<P fontSize={'12'}>{type}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Quantity</P></HeaderCell>
              <Cell>{({ quantity }) => (<P fontSize={'12'}>{quantity}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Price</P></HeaderCell>
              <Cell>{({ price }) => (<P fontSize={'12'}>{price}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Percentage</P></HeaderCell>
              <Cell>{({ percentage }) => (<P fontSize={'12'}>{percentage}</P>)}</Cell>
            </Column>
            <Column flexGrow={3}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Created date</P></HeaderCell>
              <Cell>{({ createdAt }) => (<P fontSize={'12'}>{moment(createdAt).format('ll')}</P>)}</Cell>
            </Column>
          </Table>
        ) : <NoData />}
      </Container>
    </>
  );
};

export default index;