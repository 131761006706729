import { post } from '../../components/common/http';
import { getAccountInfo } from '../../components/Layout/reducers/session';

export const login = async ({ values, dispatch, addToast }) => {
  try {
    await post('/dashboard/login', values);
    await dispatch(getAccountInfo());
  } catch ({ response }) {

    return (
      addToast(response?.data?.message || 'Server Error', {
        appearance: 'error',
        autoDismiss: true,
      })
    );
  }
};