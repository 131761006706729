import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../components/common/http';

// Actions
export const fetchTrades = createAsyncThunk('trades/fetchTrades', async (filters) => {
  const filterParams = new URLSearchParams(Object.assign({}, filters)).toString();
  const data = await get(`/dashboard/trades?${filterParams}`);
  return data;
});

export const fetchPairs = createAsyncThunk('trades/fetchPairs', async () => {
  const data = await get('/settings/pairs');
  return data;
});

// Slice
const slice = createSlice({
  name: 'tradesManagement',
  initialState: {
    data: [],
    status: 'idle',
    isLoading: false,
    error: null,
    pairs: [],
  },
  extraReducers: {
    [fetchTrades.pending]: (state) => {
      state.status = 'loading';
      state.isLoading = true;
    },
    [fetchTrades.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data;
      state.isLoading = false;
    },
    [fetchTrades.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.isLoading = false;
    },
    [fetchPairs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.pairs = action.payload.data;
    },
  }
});

export default slice.reducer;