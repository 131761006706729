import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Contain from '../../components/Contain';
import Container from '../../components/Container';
import P from '../../components/P';
import Button from '../../components/Button';
import { Header, DataFooter, DataColumn } from '../../components/Collection';
import TradesList from '../../components/TradesList';
import FilterTab from '../../components/FilterTab';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchUsersDetails, fetchRevenueStats, fetchTrades, fetchTradesStats } from './reducers';
import WelcomeMessagesText from '../../components/WelcomeMessagesText';
import Pagination from '../../components/Pagination';

const tabsFilters = [
  // closed
  {
    label: 'All',
    value: 'all',
    name: '',
  },
  {
    label: 'Closed',
    value: 'closed',
    name: 'closed',
  },
  // paused
  {
    label: 'Active',
    value: 'active',
    name: 'closed',
  },
  {
    label: 'Paused',
    name: 'paused',
    value: 'paused',
  },
];

const index = () => {
  let { id, status } = useParams();
  const [filters, setFilters] = useState({ userId: id });
  const [currentPage, setCurrentPage] = useState(filters.page ? filters.page - 1 : 0);
  const { data, stats, trades, tradesStats } = useSelector(state => state.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUsersDetails(id));
    dispatch(fetchRevenueStats(id));
    dispatch(fetchTradesStats(id));
  }, []);

  const handleFilters = ({ name, value }) => {
    if (name === 'symbol') {
      const filtersCopy = { ...filters };
      value === 'All' && delete filtersCopy.symbol;
      const obj = value !== 'All' ? { [name]: value } : null;
      setFilters({
        ...filtersCopy,
        ...obj
      });
    } else {
      value === 'paused' ? delete filters.closed : delete filters.paused;
      setFilters(value === 'all' ? { userId: id } : {
        ...filters,
        [name]: name === 'paused' ? true : value === 'active' ? false : true,
      });
    }
  };

  const handlePagination = ({ selected }) => {
    if (selected !== currentPage) {
      setFilters({
        ...filters,
        limit: 20,
        offset: selected * 20,
      });
      setCurrentPage(selected);
    }
  };

  useEffect(() => {
    if (!status || status === 'all') {
      navigate(`/users-details/${id}/all`);
      handleFilters({ name: '', value: 'all' });
    } else {
      handleFilters({ name: status === 'paused' ? 'paused' : 'closed', value: status });
    }
  }, [status]);

  useEffect(() => {
    if (Object.keys(filters).length || status === 'all') {
      dispatch(fetchTrades(filters));
    }
  }, [filters, trades?.count]);

  return (
    <>
      <Helmet>
        <title>User Details | Leekz</title>
      </Helmet>

      <Contain direction={'row'}>
        <Header>
          <Container>
            <Contain margin={'50px 0 0 0'} direction={'column'}>
              <Contain justify={'space-between'}>
                <WelcomeMessagesText fontSize={33}>{data?.Email}</WelcomeMessagesText>
                <WelcomeMessagesText fontSize={33}>{data?.createdAt}</WelcomeMessagesText>
              </Contain>
              {/* Users count */}
              <P margin={'20px 0 10px'} fontWeight={700}>Revenue</P>
              <Contain>
                <DataFooter>
                  <DataColumn noRadius height={'152px'} padding={'0'} justify={'center'} align={'center'} width={'25%'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Today</P>
                      <P margin={'7px 0 0'} fontSize={33}>${stats?.today?.profit.toFixed()}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius width={'25%'} height={'152px'} padding={'0'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>This week</P>
                      <P margin={'7px 0 0'} fontSize={33}>${stats?.thisWeek?.profit.toFixed()}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>This month</P>
                      <P margin={'7px 0 0'} fontSize={33}>${stats?.thisMonth?.profit.toFixed()}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Total</P>
                      <P margin={'7px 0 0'} fontSize={33}>${stats?.allTime?.profit.toFixed()}</P>
                    </Contain>
                  </DataColumn>
                </DataFooter>
              </Contain>

              {/* Trades Stats */}
              <P margin={'20px 0 10px'} fontWeight={700}>Trades Stats</P>
              <DataFooter>
                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain width='100%' justify={'center'}>
                    <Contain
                      direction={'column'}
                      align={'center'}>
                      <P fontSize={18} margin={'0 0 5px'}>Closed Trades</P>
                      <P fontSize={60} lineHeight='49px'>{tradesStats?.closed}</P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/closed')} noBorder activeHover outline>View Closed Trades</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'}>
                    <Contain direction={'column'} align={'center'}>
                      <P fontSize={18} margin={'0 0 5px'} >Active Trades</P>
                      <P fontSize={60} lineHeight='49px'>{tradesStats?.active}</P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/active')} noBorder activeHover outline>View Active Trades</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'}>
                    <Contain
                      direction={'column'}
                      align={'center'}>
                      <P fontSize={18} margin={'0 0 5px'} >
                        Paused Trades
                      </P>
                      <P fontSize={60} lineHeight='49px'>
                        {tradesStats?.paused}
                      </P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/paused')} noBorder activeHover outline>View Paused Trades</Button>
                </DataColumn>
              </DataFooter>

              {/* Payments Stats */}
              <P margin={'20px 0 10px'} fontWeight={700}>Payments Stats</P>
              <DataFooter>
                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'}>
                    <Contain
                      direction={'column'}
                      align={'center'}>
                      <P fontSize={18} margin={'0 0 5px'} >
                        Made Payments
                      </P>
                      <P fontSize={60} lineHeight='49px'>
                        {/* {payments?.succeeded} */}
                      </P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/paid')} noBorder activeHover outline>View Made Payments</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'}>
                    <Contain
                      direction={'column'}
                      align={'center'}
                    >
                      <P fontSize={18} margin={'0 0 5px'} >
                        Pending Payments
                      </P>
                      <P fontSize={60} lineHeight='49px'>
                        {/* {payments?.pending} */}
                      </P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/pending')} noBorder activeHover outline>View Pending Payments</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}
                >
                  <Contain
                    width='100%'
                    justify={'center'}>
                    <Contain
                      direction={'column'}
                      align={'center'}
                    >
                      <P fontSize={18} margin={'0 0 5px'} >
                        Overdue Payments
                      </P>
                      <P fontSize={60} lineHeight='49px'>
                        {/* {payments?.overdue} */}
                      </P>
                    </Contain>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/overdue')} noBorder activeHover outline>View Overdue Payments</Button>
                </DataColumn>
              </DataFooter>
            </Contain>
          </Container>
        </Header>
      </Contain>

      <Container>
        <P margin={'20px 0 10px'} fontWeight={700}>Last Trades</P>
        <Contain>
          {tabsFilters.map(({ label, value }) => (<FilterTab width='109px' fontSize={'16px'} fontWeight={'bold'} key={label} active={status === value ? true : false} onClick={() => {
            setCurrentPage(0);
            setFilters({
              ...filters,
              limit: 20,
              offset: 0,
            });
            navigate(`/users-details/${id}/${value}`);
          }}>{label}</FilterTab>))}
        </Contain>

        <TradesList data={trades?.trades} />

        <Contain margin={'20px 0 20px 0'} justify={'center'} align={'center'}>
          <Pagination
            pageCount={trades?.count / 20 || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={currentPage}
          />
        </Contain>

      </Container>
    </>
  );
};

export default index;