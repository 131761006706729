import styled from '@emotion/styled';

const WelcomeMessagesText = styled.h1({
  fontSize: '33px',
  '@media (max-width: 875px)': {
    fontSize: '22px',
  },
  '@media (max-width: 450px)': {
    fontSize: '16px',
  },
});


export default WelcomeMessagesText;
