import styled from '@emotion/styled';

const Badge = styled.div(({ margin, color }) => ({
  ...margin && { margin: margin },
  padding: '0.25rem .55rem',
  borderRadius: '0.5rem',
  border: '1px solid transparent',
  backgroundColor: color === 'blue' ? '#43bccd' : color === 'red' ? '#ea3546' : color === 'yellow' ? '#f9c80e' : '#3ab86c', // green default color
}));

export default Badge;