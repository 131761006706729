import { createSlice } from '@reduxjs/toolkit';
import { get } from '../../common/http';


// Slice
const slice = createSlice({
  name: 'session',
  initialState: {
    accountInfo: null,
    isLoggedIn: false,
    isLoading: false,
    error: false,
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getAccountInfoSuccess: (state, action) => {
      state.accountInfo = action.payload;
      state.isLoading = false;
    },
    getAccountIsLoggedInSuccess: (state) => {
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    loggedOutSuccess: (state) => {
      state.isLoggedIn = false;
      state.accountInfo = null;
      state.isLoading = false;
    }
  },
});
export default slice.reducer;

const {
  startLoading,
  hasError,
  getAccountInfoSuccess,
  getAccountIsLoggedInSuccess,
  loggedOutSuccess,
} = slice.actions;

// Actions 
export const getAccountInfo = () => async (dispatch) => {
  let accountInfo;
  dispatch(startLoading);
  try {
    accountInfo = await get('/session');
  } catch (error) {
    hasError(error);
  }

  dispatch(getAccountInfoSuccess(accountInfo?.data));
  dispatch(getAccountIsLoggedInSuccess(accountInfo?.data));

  return accountInfo;
};

export const logout = () => async (dispatch) => {
  dispatch(startLoading);
  let logoutInfo;
  try {
    logoutInfo = await get('/logout');
  } catch (error) {
    hasError(error);
  }
  dispatch(loggedOutSuccess());
  return logoutInfo;
};





