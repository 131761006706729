import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from './routes';
import globalStyle from './assets/styles/index.scss';
import { ThemeProvider, Global, css } from '@emotion/react';
import theme from './theme';
import store from './store';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Global
          styles={css`
              ${globalStyle}
            `}
        />
        <ToastProvider autoDismiss autoDismissTimeout={10000}>
          <Router>
            <Routes>
              {routes.map(({ Layout, name, route, isPrivate, Component }) => (
                <Route key={name} path={route} exact element={<Layout Component={Component} isPrivate={isPrivate} pageName={name} />} />
              ))}
            </Routes>
          </Router>
        </ToastProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
