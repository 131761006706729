import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SelectComponent from '../../../components/Select';
import Contain from '../../../components/Contain';
import { fetchPairs } from '../reducers';
import FilterTab from '../../../components/FilterTab';
import { fetchUsers } from '../../Users/reducers';

const tabsFilters = [
  // closed
  {
    label: 'All',
    value: 'all',
    name: '',
  },
  {
    label: 'Closed',
    value: 'closed',
    name: 'closed',
  },
  // paused
  {
    label: 'Active',
    value: 'active',
    name: 'closed',
  },
  {
    label: 'Paused',
    name: 'paused',
    value: 'paused',
  },
];

const Filters = ({ handler, activeTab, filters, setFilters, setCurrentPage }) => {
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const { pairs } = useSelector(state => state.tradesManagement);
  const { data } = useSelector(state => state.users);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPairs());
  }, []);

  useEffect(() => {
    dispatch(fetchUsers({ search: searchFilter }));
  }, [searchFilter]);

  return (
    <Contain margin={'0 0 20px'} direction={'column'}>
      <Contain>
        {tabsFilters.map(({ label, value }) => (<FilterTab width='109px' fontSize={'16px'} fontWeight={'bold'} key={label} active={activeTab === value ? true : false} onClick={() => {
          setCurrentPage(0);
          setFilters({
            ...filters,
            limit: 20,
            offset: 0,
          });
          navigate(`/trades-management/${value}`);
        }}>{label}</FilterTab>))}
      </Contain>
      <Contain alignItems={'center'} justify={'space-between'}>
        <SelectComponent height='50px' width={'800px'} margin={'0 10px 0 0'}
          handler={({ value }) => handler({ name: 'search', value: value })}
          onInputChange={(e) => setSearchFilter(e)}
          options={
            data?.data && [{ Email: 'All', value: '' }, ...data.data]?.map(user => ({
              label: user.Email,
              value: user.ID,
            }))
          }
          placeholder='Search' />

        <SelectComponent defaultValue={{ label: 'All', value: 'All' }} height='50px' width={'800px'} handler={({ value }) => handler({ name: 'symbol', value: value })}
          options={
            [{ symbol: 'All' }, ...pairs]?.map(x => ({
              label: x.symbol,
              value: x.symbol,
            }))
          }
          placeholder='Symbol' />
      </Contain>
    </Contain>
  );
};

Filters.propTypes = {
  setCurrentPage: PropTypes.object,
  filters: PropTypes.object,
  activeTab: PropTypes.string,
  setFilters: PropTypes.func,
  handler: PropTypes.func,
};

export default Filters;