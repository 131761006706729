import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import Contain from '../../components/Contain';
import Container from '../../components/Container';
import P from '../../components/P';
import Button from '../../components/Button';
import { Header, DataFooter, DataColumn } from '../../components/Collection';
import { fetchUsersStats, fetchTradesStats, fetchPaymentsStats, } from './reducers';
import { fetchTrades } from '../TradesManagement/reducers';
import TradesList from '../../components/TradesList';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import WelcomeMessagesText from '../../components/WelcomeMessagesText';

const index = () => {
  const { accountInfo } = useSelector(state => state.session);
  const { users, trades, payments, usersStatsLoading, tradeStatsLoading, paymentsStatsLoading } = useSelector(state => state.stats);
  const { data, isLoading } = useSelector(state => state.tradesManagement);
  const welcomeMessages = ['Greetings', 'Good Morning', 'Good Afternon', 'Good Evening'][Math.floor((new Date().getHours() / 24) * 4)];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tradesFilters = {
    closed: false,
    limit: 25,
  };

  useEffect(() => {
    dispatch(fetchUsersStats());
    dispatch(fetchTradesStats());
    dispatch(fetchPaymentsStats());
    dispatch(fetchTrades(tradesFilters));
  }, []);

  const LoaderHandler = (loadingState, data) => {
    return (loadingState ? <LoadingSpinner /> : data);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Leekz</title>
      </Helmet>

      <Contain direction={'row'}>
        <Header>
          <Container>
            <Contain margin={'50px 0 0 0'} direction={'column'}>
              <WelcomeMessagesText>{welcomeMessages}, {accountInfo?.email}</WelcomeMessagesText>
              {/* Users count */}
              <P margin={'20px 0 10px'} fontWeight={700}>Users count</P>
              <Contain>
                <DataFooter>
                  <DataColumn noRadius height={'152px'} padding={'0'} justify={'center'} align={'center'} width={'25%'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Total Users</P>
                      <P margin={'7px 0 0'} fontSize={33}>{LoaderHandler(usersStatsLoading, users?.total)}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius width={'25%'} height={'152px'} padding={'0'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Active Users</P>
                      <P margin={'7px 0 0'} fontSize={33}>{LoaderHandler(usersStatsLoading, users?.active)}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Pending Users</P>
                      <P margin={'7px 0 0'} fontSize={33}>{LoaderHandler(usersStatsLoading, users?.pending)}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                    <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                      <P fontSize={18}>Blocked Users</P>
                      <P margin={'7px 0 0'} fontSize={33}>{LoaderHandler(usersStatsLoading, users?.blocked)}</P>
                    </Contain>
                  </DataColumn>

                  <DataColumn noRadius height={'152px'} padding={'0'} width={'25%'} justify={'center'} align={'center'}>
                    <Button small outline noBorder activeHover minWidth={'173px'} margin={'0 18px 0 0'} minHeight={'40px'} shadowColour={'#EAF6F6'} onClick={() => navigate('/users')}>
                      <P>View All Users</P>
                    </Button>
                  </DataColumn>
                </DataFooter>
              </Contain>

              {/* Trades Stats */}
              <P margin={'20px 0 10px'} fontWeight={700}>Trades Stats</P>
              <DataFooter>
                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain width='100%' justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'}>Closed Trades</P>
                    <P fontSize={60} lineHeight='49px'>{LoaderHandler(tradeStatsLoading, trades?.closed)}</P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/closed')} noBorder activeHover outline>View Closed Trades</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'} direction={'column'} align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'} >Active Trades</P>
                    <P fontSize={60} lineHeight='49px'>{LoaderHandler(tradeStatsLoading, trades?.active)}</P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/active')} noBorder activeHover outline>View Active Trades</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'} direction={'column'}
                    align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'}>
                      Paused Trades
                    </P>
                    <P fontSize={60} lineHeight='49px'>
                      {LoaderHandler(tradeStatsLoading, trades?.paused)}
                    </P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/trades-management/paused')} noBorder activeHover outline>View Paused Trades</Button>
                </DataColumn>
              </DataFooter>

              {/* Payments Stats */}
              <P margin={'20px 0 10px'} fontWeight={700}>Payments Stats</P>
              <DataFooter>
                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'} direction={'column'}
                    align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'}>
                      Made Payments
                    </P>
                    <P fontSize={60} lineHeight='49px'>
                      {LoaderHandler(paymentsStatsLoading, payments?.succeeded)}
                    </P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/paid')} noBorder activeHover outline>View Made Payments</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}>
                  <Contain
                    width='100%'
                    justify={'center'} direction={'column'}
                    align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'}>
                      Pending Payments
                    </P>
                    <P fontSize={60} lineHeight='49px'>
                      {LoaderHandler(paymentsStatsLoading, payments?.pending)}
                    </P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/pending')} noBorder activeHover outline>View Pending Payments</Button>
                </DataColumn>

                <DataColumn
                  border='1px solid #EAF6F6'
                  hover
                  width={'32%'}
                  justify={'space-between'}
                  align={'center'}
                >
                  <Contain
                    width='100%'
                    justify={'center'} direction={'column'}
                    align={'center'}>
                    <P fontSize={18} margin={'0 0 5px'}>
                      Overdue Payments
                    </P>
                    <P fontSize={60} lineHeight='49px'>
                      {LoaderHandler(paymentsStatsLoading, payments?.overdue)}
                    </P>
                  </Contain>
                  <Button width='100%' maxWidth={'215px'} height={'40px'} shadowColour={'#EAF6F6'} fontSize={16} onClick={() => navigate('/payments/overdue')} noBorder activeHover outline>View Overdue Payments</Button>
                </DataColumn>
              </DataFooter>
            </Contain>
          </Container>
        </Header>
      </Contain>

      <Container>
        <P margin={'20px 0 10px'} fontWeight={700}>Last Trades</P>
        {isLoading ? (
          <Contain align={'center'} justify={'center'} height={'500px'}>
            <LoadingSpinner />
          </Contain>) : <TradesList data={data?.trades} />}
      </Container>
    </>
  );
};

export default index;