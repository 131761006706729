import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchTrades } from './reducers';
import Container from '../../components/Container';
import Pagination from '../../components/Pagination';
import Contain from '../../components/Contain';
import Filters from './components/Filters';
import TradesList from '../../components/TradesList';
import LoadingSpinner from '../../components/LoadingSpinner';
import WelcomeMessagesText from '../../components/WelcomeMessagesText';

const index = () => {
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(filters.page ? filters.page - 1 : 0);
  const { data, isLoading } = useSelector(state => state.tradesManagement);
  let { status } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFilters = ({ name, value }) => {
    const filtersCopy = { ...filters };
    if (name === 'search') {
      setFilters({
        ...filtersCopy,
        userId: value == undefined ? '' : value,
      });
    }
    else if (name === 'symbol') {
      value === 'All' && delete filtersCopy.symbol;
      const obj = value !== 'All' ? { [name]: value } : null;
      setFilters({
        ...filtersCopy,
        ...obj
      });
    } else {
      value === 'paused' ? delete filters.closed : delete filters.paused;
      setFilters(value === 'all' ? { userId: filters?.userId ? filters?.userId : '' } : {
        ...filters,
        [name]: name === 'paused' ? true : value === 'active' ? false : true,
      });
    }
  };

  const handlePagination = ({ selected }) => {
    if (selected !== currentPage) {
      setFilters({
        ...filters,
        limit: 20,
        offset: selected * 20,
      });
      setCurrentPage(selected);
    }
  };

  useEffect(() => {
    if (!status || status === 'all') {
      navigate('/trades-management/all');
      handleFilters({ name: '', value: 'all' });
    } else {
      handleFilters({ name: status === 'paused' ? 'paused' : 'closed', value: status });
    }
  }, [status]);

  useEffect(() => {
    if (Object.keys(filters).length || status === 'all') {
      dispatch(fetchTrades(filters));
    }
  }, [filters, data?.count]);

  return (
    <Container>
      <Helmet>
        <title>
          Trades Management | Leekz
        </title>
      </Helmet>

      <Contain margin={'90px 0 0 0'}>
        <WelcomeMessagesText fontSize={'36'}>Trades Management</WelcomeMessagesText>
      </Contain>

      <Filters handler={handleFilters} activeTab={status} filters={filters} setFilters={setFilters} setCurrentPage={setCurrentPage} />

      {isLoading ?
        <Contain align={'center'} justify={'center'} height={'500px'}>
          <LoadingSpinner />
        </Contain>
        : <TradesList data={data.trades} />}

      <Contain margin={'20px 0 20px 0'} justify={'center'} align={'center'}>
        <Pagination
          pageCount={data?.count / 25 || 0}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePagination}
          forcePage={currentPage}
        />
      </Contain>

    </Container>
  );
};

export default index;