import styled from '@emotion/styled';
import P from './P';

const FilterTab = styled(P)(({ active, fontSize }) => ({
  color: active ? '#10242C' : '#3ab86c',
  cursor: 'pointer',
  margin: '25px 10px 30px 0',
  paddingBottom: active ? '11px' : '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: fontSize,

  ...(active && {
    borderBottom: '3px solid #10242C',
  }),
}));

export default FilterTab;