import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../components/common/http';

// Actions
export const fetchUsersDetails = createAsyncThunk('user/fetchUsersDetails', async (id) => {
  const data = await get(`/users/${id}`);
  return data;
});

export const fetchRevenueStats = createAsyncThunk('user/fetchRevenueStats', async (id) => {
  const data = await get(`/dashboard/trades/stats/${id}`);
  return data;
});

export const fetchTrades = createAsyncThunk('user/fetchTrade', async (filters) => {
  const filterParams = new URLSearchParams(Object.assign({}, filters)).toString();
  const data = await get(`/dashboard/trades?${filterParams}`);
  return data;
});

export const fetchTradesStats = createAsyncThunk('user/fetchTradeStats', async (id) => {
  const data = await get(`/dashboard/trades-summary?userId=${id}`);
  return data;
});

// Slice
const slice = createSlice({
  name: 'userDetails',
  initialState: {
    data: [],
    stats: [],
    trades: [],
    tradesStats: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    //user details
    [fetchUsersDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUsersDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data.data[0];
    },
    [fetchUsersDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },

    //revenue stats
    [fetchRevenueStats.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchRevenueStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.stats = action.payload.data.stats;
    },
    [fetchRevenueStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },

    //user trades
    [fetchTrades.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchTrades.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.trades = action.payload.data;
    },
    [fetchTrades.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },

    //fetch trades stats
    [fetchTradesStats.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchTradesStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.tradesStats = action.payload.data.data;
    },
    [fetchTradesStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  }
});

export default slice.reducer;