import React from 'react';
import Home from './pages/Home';
import Login from './pages/Auth';
import TradesManagement from './pages/TradesManagement';
import TradeDetails from './pages/TradeDetails';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import Payments from './pages/Payments';
import Settings from './pages/Settings';

import Layout from './components/Layout';

const routes = [
  {
    route: '/',
    name: 'Home',
    Component: Home,
    isPrivate: true,
    Layout,
  },
  {
    route: '/login',
    name: 'Login',
    Component: Login,
    isPrivate: false,
    Layout
  },
  {
    route: '/users/:status',
    name: 'Users',
    Component: Users,
    isPrivate: true,
    Layout,
  },
  {
    route: '/users',
    name: 'Users',
    Component: Users,
    isPrivate: true,
    Layout,
  },
  {
    route: '/users-details/:id',
    name: 'User Details',
    Component: UserDetails,
    isPrivate: true,
    Layout,
  },
  {
    route: '/users-details/:id/:status',
    name: 'User Details',
    Component: UserDetails,
    isPrivate: true,
    Layout,
  },
  {
    route: '/trades-management/:status',
    name: 'Trades Management',
    Component: TradesManagement,
    isPrivate: true,
    Layout,
  },
  {
    route: '/trades-management',
    name: 'Trades Management',
    Component: TradesManagement,
    isPrivate: true,
    Layout,
  },
  {
    route: '/trades-details/:id',
    name: 'Trade Details',
    Component: TradeDetails,
    isPrivate: true,
    Layout,
  },
  {
    route: '/payments/:status',
    name: 'Payments',
    Component: Payments,
    isPrivate: true,
    Layout,
  },
  {
    route: '/payments',
    name: 'Payments',
    Component: Payments,
    isPrivate: true,
    Layout,
  },
  {
    route: '/settings',
    name: 'settings',
    Component: Settings,
    isPrivate: true,
    Layout,
  },
  {
    route: '/profile',
    name: 'Profile',
    Component: () => { return (<p>my profile</p>); },
    isPrivate: true,
    Layout,
  }
];

export default routes;
