import React from 'react';
import { useDispatch } from 'react-redux';
import Contain from '../Contain';
import NoData from '../NoData';
import Badge from '../Badge';
import P from '../P';
import { fetchTrades } from '../../pages/TradesManagement/reducers';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import { put, remove } from '../common/http';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import swal from 'sweetalert2';
import moment from 'moment';
import Settings from '../../assets/images/Settings.svg';
import 'rsuite-table/dist/css/rsuite-table.css';
import ToggleVisible from '../common/ToggleVisible';

const index = ({ data }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleConfirmation = async (currentCase, _id) => {
    return (
      swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to ${currentCase} this trade?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3ab86c',
        cancelButtonColor: '#F42B03',
        confirmButtonText: 'Yes'
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          //TODO: REFACTOR CODE
          currentCase === 'delete' ? await remove(`/trades/${_id}`) : await put(`/trades/${_id}/${currentCase}`);
          await dispatch(fetchTrades());
          swal.fire(
            currentCase === 'pause' ? 'Paused' : currentCase === 'resume' ? 'Resumed' : currentCase === 'delete' ? 'Deleted' : 'Sold',
            'Action completed successfully',
            'success',
          ).then(() => dispatch(fetchTrades()));
        }
      })
    );
  };

  const upperCase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <>
      {data?.length > 0 ?
        <Table onRowClick={({ _id }) => navigate(`/trades-details/${_id}`)} wordWrap={true} autoHeight rowHeight={70} data={data}>

          <Column width={100}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Pair</P></HeaderCell>
            <Cell>{({ symbol }) => (<P fontWeight={700} fontSize={'12'}>{symbol}</P>)}</Cell>
          </Column>

          <Column width={220}>
            <HeaderCell><P fontSize={12} fontWeight={700}>User Id</P></HeaderCell>
            <Cell>{({ userId }) => (<P fontSize={'12'}>{userId}</P>)}</Cell>
          </Column>

          <Column width={150}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Profit</P></HeaderCell>
            <Cell>{({ profit, usdProfit }) => (<P fontSize={12}>{parseFloat(Number(profit).toFixed(6))}/{parseFloat(Number(usdProfit).toFixed(6))}</P>)}</Cell>
          </Column>

          <Column width={215}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Strategy Info</P></HeaderCell>
            <Cell>{(data) => (
              <Contain direction={'column'}>
                <P fontSize={12}>Percent: {data.strategy.percentage}</P>
                <P fontSize={12}>Depths: {data.history.length}</P>
                <P fontSize={12}>Base Quantity: {data.history.reduce((quantity, { quantity: nextQty, price }) => (quantity || 0) + (nextQty * price), 0).toFixed(6)}</P>
                <P fontSize={12}>Quote Quantity: {data.history.reduce((quantity, { quantity: nextQty }) => (quantity || 0) + nextQty, 0).toFixed(6)}</P>
              </Contain>
            )}</Cell>
          </Column>

          <Column width={90}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Exchange</P></HeaderCell>
            <Cell>{({ exchange }) => (<P fontSize={12}>{upperCase(exchange.name)}</P>)}</Cell>
          </Column>

          <Column width={90}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Paused</P></HeaderCell>
            <Cell>{({ paused }) => (<Badge color={paused === 'Yes' ? 'yellow' : undefined}><P fontSize={12}>{paused ? 'Yes' : 'No'}</P></Badge>)}</Cell>
          </Column>

          <Column width={190}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Position Info</P></HeaderCell>
            <Cell>{({ position }) => (
              <Contain direction={'column'}>
                <P fontSize={12}>Type: {position.type && position.type}</P>
                <P fontSize={12}>Last Price: {position.price && position.price}</P>
              </Contain>
            )}</Cell>
          </Column>

          <Column width={120}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Created At</P></HeaderCell>
            <Cell>{({ createdAt }) => (<P fontSize={'12'}>{moment(createdAt).format('ll')}</P>)}</Cell>
          </Column>

          <Column width={100}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Status</P></HeaderCell>
            <Cell>{({ closed }) => (<Badge color={closed ? 'red' : undefined}> <P fontSize={'12'}>{closed ? 'Closed' : 'Open'}</P></Badge>)}</Cell>
          </Column>

          <Column width={90}>
            <HeaderCell><P fontSize={12} fontWeight={700}>Options</P></HeaderCell>
            <Cell onClick={(e) => e.stopPropagation()}>
              {({ _id }) => {
                return (
                  <Contain ref={ref}>
                    <Tooltip
                      isOpen={isComponentVisible?.open == true && isComponentVisible?.id == _id ? true : false}
                      content={(
                        <Contain padding={'5px'} direction={'column'} justify={'center'} align={'center'}>
                          <P hover margin={'2px'} pointer onClick={() => handleConfirmation('pause', _id)}>Pause</P>
                          <P hover margin={'2px'} pointer onClick={() => handleConfirmation('resume', _id)}>Resume</P>
                          <P hover margin={'2px'} pointer onClick={() => handleConfirmation('delete', _id)}>Delete</P>
                          <P hover margin={'2px'} pointer onClick={() => handleConfirmation('sell', _id)}>Sell</P>
                        </Contain>
                      )}
                      direction='bottom'>
                      <P onClick={() => setIsComponentVisible({ id: _id, open: !isComponentVisible.open })} fontSize={12} margin={'0 14px 0 14px'} pointer><img src={Settings} /></P>
                    </Tooltip>
                  </Contain>
                );
              }}
            </Cell>
          </Column>

        </Table>
        : <NoData />}
    </>
  );
};


export default index;