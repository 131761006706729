import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import session from './components/Layout/reducers/session';
import tradesManagement from './pages/TradesManagement/reducers';
import tradeDetails from './pages/TradeDetails/reducers';
import users from './pages/Users/reducers';
import userDetails from './pages/UserDetails/reducers';
import stats from './pages/Home/reducers';

const reducer = combineReducers({
  session,
  tradesManagement,
  tradeDetails,
  users,
  userDetails,
  stats,
});

const store = configureStore({
  reducer,
});

export default store;
