import React from 'react';
import { Helmet } from 'react-helmet';
import Container from '../../components/Container';
import Contain from '../../components/Contain';
import NoData from '../../components/NoData';
import Pagination from '../../components/Pagination';
import Filters from './components/Filters';
import H1 from '../../components/H1';

const index = () => {
  return (
    <Container>
      <Helmet>
        <title>
          Payments | Leekz
        </title>
      </Helmet>

      <Contain margin={'90px 0 0 0'}>
        <H1 fontSize={'36'}>Payments</H1>
      </Contain>

      <Filters />
      <NoData />

      <Contain margin={'20px 0 20px 0'} justify={'center'} align={'center'}>
        <Pagination force={10 - 1} total={10} />
      </Contain>
    </Container>
  );
};

export default index;