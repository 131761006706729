import styled from '@emotion/styled';

export const HeaderStyle = styled.div(({ coloredHeader, theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '70px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 30px',
  backgroundColor: coloredHeader ? theme.colors.disabledBlue : theme.colors.white,
  zIndex: 4,
  'a': {
    textDecoration: 'none'
  }
}));

export const Avatar = styled.div(({ theme }) => ({
  height: '50px',
  maxWidth: '50px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: `1px solid ${theme.colors.turquoise}`,
  cursor: 'pointer',
  '@media (max-width: 1024px)': {
    display: 'none'
  },
}));

export const Logo = styled.img({
  height: '45px',
});

export const NavigationLinks = styled.ul(({ mobile }) => ({
  maxWidth: '680px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginLeft: '10px',
  marginRight: '10px',
  listStyle: 'none',
  '@media (max-width: 1024px)': {
    display: !mobile && 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '25px',
    marginLeft: '0',
  },
}));

export const Dropdown = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '68px',
  width: '200px',
  backgroundColor: theme?.colors?.white,
  border: `1px solid ${theme?.colors?.turquoise}`,
  borderRadius: '10px',
  padding: '20px 30px',
  overflow: 'hidden',
  right: '20px',
  display: 'flex',
  flexDirection: 'column',
}));

export const DropdownItem = styled.a(() => ({
  padding: '5px',
  cursor: 'pointer',
  display: 'flex'
}));

export const Item = styled.li(({ theme }) => ({
  a: {
    textDecoration: 'none',
    color: theme.colors.primary,
    '&:hover, &.active': {
      textDecoration: 'underline',
      textDecorationThickness: '3px',
      textUnderlineOffset: '6px',
    },
  },
  '@media (max-width: 1024px)': {
    height: '30px'
  },
}));

export const MobileMenu = styled.div(({ open, theme }) => ({
  height: '900px',
  width: '320px',
  position: 'fixed',
  top: '0',
  left: open ? '0' : '-321px',
  padding: '40px',
  transition: '.75s ease',
  backgroundColor: theme.colors.primary,
  '@media (min-width: 1024px)': {
    display: 'none'
  },
}));

export const BurgerToggle = styled.div(() => ({
  '@media (min-width: 1024px)': {
    display: 'none'
  },
}));
