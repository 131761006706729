import React from 'react';
import styled from '@emotion/styled';

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.formBtn ? 'auto' : '100vh'};

  div {
    position: relative;
    width: 80px;
    height: ${props => props.formBtn ? '60px' : '80px'};
  }
  
  span {
    position: absolute;
    top: ${props => props.formBtn ? '40%' : '33px'};
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${props => props.formBtn ? 'white' : props.theme.colors.turquoise};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    
    &:nth-of-type(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    
    &:nth-of-type(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-of-type(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    
    &:nth-of-type(4) {
    left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
`;

const LoadingSpinner = (props) => {
  return (
    <Loader {...props}>
      <div>
        <span />
        <span />
        <span />
        <span />
      </div>
    </Loader>
  );
};

export default LoadingSpinner;
