import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'; // createAsyncThunk
import { get } from '../../components/common/http';

// Actions
export const fetchUsersStats = createAsyncThunk('stats/fetchUsers', async () => {
  const data = await get('/dashboard/users-summary');
  return data;
});

export const fetchTradesStats = createAsyncThunk('stats/fetchTrades', async () => {
  const data = await get('/dashboard/trades-summary');
  return data;
});

export const fetchPaymentsStats = createAsyncThunk('stats/fetchPayments', async () => {
  const data = await get('/dashboard/payments-summary');
  return data;
});




// Slice
const slice = createSlice({
  name: 'stats',
  initialState: {
    users: [],
    trades: [],
    payments: [],
    usersStatsLoading: false,
    tradesStatsLoading: false,
    paymentsStatsLoading: false,
    status: 'idle',
    error: null,
  },
  extraReducers: {
    // Users Stats
    [fetchUsersStats.pending]: (state) => {
      state.status = 'loading';
      state.usersStatsLoading = true;
    },
    [fetchUsersStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload.data.data;
      state.usersStatsLoading = false;
    },
    [fetchUsersStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.usersStatsLoading = false;
    },

    // Trades Stats
    [fetchTradesStats.pending]: (state) => {
      state.status = 'loading';
      state.tradesStatsLoading = true;
    },
    [fetchTradesStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.trades = action.payload.data.data;
      state.tradesStatsLoading = false;
    },
    [fetchTradesStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.tradesStatsLoading = false;
    },

    // Payments Stats
    [fetchPaymentsStats.pending]: (state) => {
      state.status = 'loading';
      state.paymentsStatsLoading = true;
    },
    [fetchPaymentsStats.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.payments = action.payload.data.data;
      state.paymentsStatsLoading = false;
    },
    [fetchPaymentsStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.paymentsStatsLoading = false;
    },

  }
});

export default slice.reducer;