import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CheckboxContainer = styled.div({
  display: 'flex',
  position: 'relative',
  width: 'max-content',
});

const CheckboxLabel = styled.label({
  padding: '0 0 0 30px',
  userSelect: 'none',
  cursor: 'pointer',
});

const CheckboxInput = styled.input(({ theme }) => ({
  position: 'absolute',
  opacity: 0,
  height: 0,
  width: 0,
  '&:checked ~ .remember': {
    background: theme.colors.primary,
    '&:after': {
      display: 'block',
    },
  },
}));

const Checkmark = styled.span(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  height: '22px',
  width: '22px',
  top: '-1px',
  left: 0,
  background: theme.colors.white,
  border: `2px solid ${theme.colors.primary}`,
  borderRadius: '4px',
  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    width: '3px',
    height: '8px',
    top: '2px',
    left: '6px',
    border: '1px solid #fff',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(45deg)',
  },
}));

const Checkbox = ({ name, label, value, handler, isChecked }) => {
  return (
    <CheckboxContainer>
      <CheckboxLabel>
        {label}
        <CheckboxInput
          type={'checkbox'}
          name={name}
          value={value}
          onChange={handler}
          checked={isChecked ? true : false}
        />
        <Checkmark className={'remember'} />
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default Checkbox;