import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/Input';
import Container from '../../components/Container';
import Contain from '../../components/Contain';
import Button from '../../components/Button';
import H1 from '../../components/H1';
import P from '../../components/P';
import AddSVG from '../../assets/images/Add.svg';
import CloseSVG from '../../assets/images/Close.svg';
import EditSVG from '../../assets/images/Edit.svg';
import { fetchPairs } from '../TradesManagement/reducers';
import PairModal from './components/PairModal';

const index = () => {
  const dispatch = useDispatch();
  const { pairs } = useSelector(state => state.tradesManagement);
  const [pairFilter, setPairFilter] = useState('');
  const [showModal, setShowModal] = useState({
    visible: false,
    type: '',
    _id: '',
  });

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setPairFilter(lowerCase);
  };

  const filteredData = pairs.filter((el) => {
    if (pairFilter === '') {
      return el;
    }
    else {
      return el.symbol.toLowerCase().includes(pairFilter);
    }
  });

  useEffect(() => {
    dispatch(fetchPairs());
  }, []);


  return (
    <Container>
      <PairModal showModal={showModal} setShowModal={setShowModal} />

      <Helmet>
        <title>
          Settings | Leekz
        </title>
      </Helmet>

      <Contain margin={'90px 0 0 0'}>
        <H1 fontSize={'36'}>Settings</H1>
      </Contain>
      <P margin={'20px 0 10px'} fontWeight={700}>Pairs</P>
      <Contain direction={'column'} padding={'20px'} maxWidth={'500px'} width={'100%'} border={'1px solid red'} borderRadius={'10px'}>
        <Button onClick={() => setShowModal({ visible: true, type: 'add' })} margin={'0 0 10px'}><img src={AddSVG} /></Button>
        <Input
          type='text'
          name='Search'
          placeholder='Search'
          height='50px'
          margin='0 0 20px'
          onChange={(e) => inputHandler(e)}
        />
        <Contain margin={'0 0 10px'} justify={'space-between'}>
          <P maxWidth={'300px'} width={'300px'}>Symbol</P>
          <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>Lot size</P>
          <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>Min notional</P>
          <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>Remove</P>
        </Contain>
        {filteredData?.map(({ _id, symbol, lotSize, minNotional, disabled }) => (
          <Contain key={_id} margin={'0 0 10px'} justify={'space-between'}>
            <P maxWidth={'300px'} width={'300px'}>{symbol}</P>
            <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>{lotSize}</P>
            <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>{minNotional}</P>
            <P maxWidth={'300px'} width={'300px'} textAlign={'center'}>
              <img onClick={() => setShowModal({ visible: true, type: 'edit', pair: { _id: _id, symbol: symbol, lotSize: lotSize, minNotional: minNotional, disabled: disabled } })} src={EditSVG} />
              <img onClick={() => setShowModal({ visible: true, type: 'remove', pair: { _id: _id, symbol: symbol } })} src={CloseSVG} />
            </P>
          </Contain>
        ))}
      </Contain>
    </Container>
  );
};

export default index;