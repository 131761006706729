import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Contain from '../../../components/Contain';
import Input from '../../../components/Input';
import FilterTab from '../../../components/FilterTab';

const tabsFilters = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Activated',
    value: 'activated',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
];

const Filters = ({ handler, activeTab, filters, setFilters, setCurrentPage, count }) => {
  const navigate = useNavigate();

  return (
    <Contain margin={'0 0 20px'} direction={'column'}>
      <Contain>
        {tabsFilters.map(({ label, value }) => (<FilterTab width='109px' fontWeight={'bold'} fontSize={'16px'} key={label} active={activeTab === value ? true : false} onClick={() => {
          setCurrentPage(0);
          count > 20 && (setFilters({
            ...filters,
            limit: 20,
            offset: 0,
          }));
          navigate(`/users/${value}`);
        }}>{label}</FilterTab>))}
      </Contain>
      <Input onChange={({ target }) => handler({ name: 'search', value: target.value })} placeholder={'Search'} height='50px' margin={'10px 0 10px'} />
    </Contain>
  );
};

Filters.propTypes = {
  handler: PropTypes.func,
  filters: PropTypes.object,
  activeTab: PropTypes.string,
  setCurrentPage: PropTypes.func,
  setFilters: PropTypes.func,
  count: PropTypes.number,

};

export default Filters;