import React, { useState } from 'react';
import { Container, FormContainer } from './styles';
import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { Formik, Form } from 'formik';
import { login } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Logo from '../../assets/images/Logo.svg';
import LoadingSpinner from '../../components/LoadingSpinner';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const Login = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const { addToast } = useToasts();
  const { isLoggedIn } = useSelector(state => state.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Container>
      <Helmet>
        <title>Log In | Leekz</title>
      </Helmet>
      <FormContainer>
        <img src={Logo} alt='logo' />
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            setDisabledButton(true);
            await login({
              values,
              dispatch,
              addToast
            }).then(() => setDisabledButton(false));
            if (isLoggedIn) {
              navigate('/');
            }
          }}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                type='text'
                name='email'
                placeholder='Email'
                onChange={handleChange}
                value={values.email}
                height='50px'
                margin='45px 0 20px'
                {...{ errors, touched }}
              />
              <Input
                type='password'
                name='password'
                placeholder='Password'
                onChange={handleChange}
                value={values.password}
                height='50px'
                margin='0 0 20px'
                {...{ errors, touched }}
              />
              {/* TODO: fix remember me */}
              <Checkbox
                name='rememberMe'
                handler={() => setRememberMe(!rememberMe)}
                label='Remember Me'
                isChecked={rememberMe}
              />
              <Button disabled={disabledButton ? true : false} type='submit' width='100%' fontSize={16} margin='20px 0'>{disabledButton ? <LoadingSpinner /> : 'Login'}</Button>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </Container>
  );
};

export default Login;