import React from 'react';
import PropTypes from 'prop-types';
import Contain from '../../../components/Contain';
import Input from '../../../components/Input';
import FilterTab from '../../../components/FilterTab';

const Filters = ({ handler }) => {

  // const statusOptions = [
  //   { label: 'Paid', value: 'paid' },
  //   { label: 'Pending', value: 'pending' },
  //   { label: 'Overdue', value: 'overdue' },
  // ];

  const tabsFilters = [
    {
      label: 'Paid',
      value: true,
      width: '200px'
    },
    {
      label: 'Pending',
      value: false,
      width: '100px'
    },
    {
      label: 'Overdue',
      value: false,
      width: '200px',
    },
  ];

  return (
    <Contain margin={'0 0 20px'} direction={'column'}>
      <Contain>
        {
          tabsFilters.map(({ label, value }) => (<FilterTab width='109px' fontSize={'16px'} fontWeight={'bold'} active={value} key={label}>{label}</FilterTab>))
        }
      </Contain>
      <Input onChange={({ target }) => handler({ name: 'search', value: target.value })} placeholder={'Search'} height='50px' margin={'10px 0 10px'} />

    </Contain>
  );
};

Filters.propTypes = {
  handler: PropTypes.func,
};

export default Filters;