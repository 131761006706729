import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../components/common/http';

// Actions
export const fetchTradesDetails = createAsyncThunk('trades/fetchTradesDetails', async (id) => {
  const data = await get(`/dashboard/trades/${id}`);
  return data;
});

// Slice
const slice = createSlice({
  name: 'tradeDetails',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchTradesDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchTradesDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload.data.trades[0];
    },
    [fetchTradesDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  }
});

export default slice.reducer;