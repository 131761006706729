import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Tooltip from 'react-tooltip-lite';
import swal from 'sweetalert2';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import { fetchUsers, blockUser, unblockUser } from './reducers';
import Container from '../../components/Container';
import P from '../../components/P';
import Badge from '../../components/Badge';
import NoData from '../../components/NoData';
import Pagination from '../../components/Pagination';
import Contain from '../../components/Contain';
import Filters from './components/Filters';
import H1 from '../../components/H1';
import Settings from '../../assets/images/Settings.svg';
import LoadingSpinner from '../../components/LoadingSpinner';
import ToggleVisible from '../../components/common/ToggleVisible';

const index = () => {
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible({});
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(filters.page ? filters.page - 1 : 0);
  const { data, isLoading } = useSelector(state => state.users);
  let { status } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFilters = ({ name, value }) => {
    setFilters({ ...filters, [name === 'search' ? 'search' : 'status']: value });
  };

  const handlePagination = ({ selected }) => {
    if (selected !== currentPage) {
      setFilters({
        ...filters,
        limit: 20,
        offset: selected * 20,
      });
      setCurrentPage(selected);
    }
  };

  const handleConfirmation = async (currentCase, _id) => {
    return (
      swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to ${currentCase} this user?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3ab86c',
        cancelButtonColor: '#F42B03',
        confirmButtonText: 'Yes'
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          currentCase == 'block' ? await dispatch(blockUser(_id)) : await dispatch(unblockUser(_id));
          await dispatch(fetchUsers(filters));
        }
      })
    );
  };

  useEffect(() => {
    if (!status || status === 'all') {
      navigate('/users/all');
      handleFilters({ name: '', value: '' });
    } else {
      handleFilters({ name: 'status', value: status });
    }
  }, [status]);

  useEffect(() => {
    dispatch(fetchUsers(filters));
  }, [filters, data?.count]);

  return (
    <Container>
      <Helmet>
        <title>
          Users | Leekz
        </title>
      </Helmet>

      <Contain margin={'90px 0 0 0'}>
        <H1 fontSize={'36'}>Users</H1>
      </Contain>

      <Filters handler={handleFilters} activeTab={status} filters={filters} setFilters={setFilters} setCurrentPage={setCurrentPage} count={data?.count} />

      {data?.data?.length > 0 ?
        isLoading ? (
          <Contain align={'center'} justify={'center'} height={'500px'}>
            <LoadingSpinner />
          </Contain>) :
          <Table onRowClick={({ ID }) => navigate(`/users-details/${ID}/all`)} wordWrap={true} autoHeight rowHeight={70} data={data?.data}>
            <Column width={310}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Id</P></HeaderCell>
              <Cell>{({ ID }) => (<P fontSize={'12'}>{ID}</P>)}</Cell>
            </Column>
            <Column width={300}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Email</P></HeaderCell>
              <Cell>{({ Email }) => (<P fontSize={'12'}>{Email}</P>)}</Cell>
            </Column>
            <Column width={200}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Invited Users</P></HeaderCell>
              <Cell><P fontSize={'12'}>N/A</P></Cell>
            </Column>
            <Column width={200}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Fee Percent</P></HeaderCell>
              <Cell><P fontSize={'12'}>N/A</P></Cell>
            </Column>
            <Column width={200}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Status</P></HeaderCell>
              <Cell>{({ Status }) => (<Badge color={Status === 'ACTIVATED' ? undefined : 'yellow'}><P fontSize={'12'}>{Status}</P></Badge>)}</Cell>
            </Column>
            <Column width={200}>
              <HeaderCell><P fontSize={12} fontWeight={700}>Options</P></HeaderCell>
              <Cell onClick={(e) => e.stopPropagation()}>
                {({ ID }) => {
                  return (
                    <Contain ref={ref}>
                      <Tooltip
                        isOpen={isComponentVisible?.open == true && isComponentVisible?.id == ID ? true : false}
                        content={(
                          <Contain padding={'5px'} direction={'column'} justify={'center'} align={'center'}>
                            <P hover margin={'2px'} pointer onClick={() => handleConfirmation('block', ID)}>Block</P>
                            <P hover margin={'2px'} pointer onClick={() => handleConfirmation('unblock', ID)}>Unblock</P>
                          </Contain>
                        )} direction='bottom'>
                        <P fontSize={12} onClick={() => setIsComponentVisible({ id: ID, open: !isComponentVisible.open })} margin={'0 14px 0 14px'} pointer><img src={Settings} /></P>
                      </Tooltip>
                    </Contain>
                  );
                }}
              </Cell>
            </Column>
          </Table>
        : <NoData />}

      <Contain margin={'20px 0 20px 0'} justify={'center'} align={'center'}>
        <Pagination
          pageCount={data?.count / 25 || 0}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePagination}
          forcePage={currentPage}
        />
      </Contain>
    </Container>
  );
};

export default index;