import { createAsyncThunk } from '@reduxjs/toolkit';
import { remove, put, post } from '../../components/common/http';

// Actions
export const removePair = createAsyncThunk('pairs/removePair', async (id) => {
  const data = await remove(`/settings/pairs/${id}`);
  return data;
});

export const editPair = createAsyncThunk('pairs/editPair', async (payload) => {
  const data = await put(`/settings/pairs/${payload.id}`, payload);
  return data;
});

export const addPair = createAsyncThunk('pairs/addPair', async (pair) => {
  const data = await post('/settings/pairs', pair);
  return data;
});
