import React from 'react';
import PropTypes from 'prop-types';
import Rodal from 'rodal';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import P from '../../../components/P';
import Contain from '../../../components/Contain';
import { addPair, removePair, editPair } from '../reducers';
import { fetchPairs } from '../../TradesManagement/reducers';

// include styles
import 'rodal/lib/rodal.css';

const PairModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  return (
    <Rodal height={showModal.type == 'remove' ? 150 : 470} visible={showModal.visible} onClose={() => setShowModal({ visible: false, type: '', pair: {} })}>
      <Formik
        initialValues={{
          symbol: showModal?.pair?.symbol ? showModal?.pair?.symbol : '',
          lotSize: '',
          minNotional: '',
          disabled: showModal?.pair?.disabled ? showModal?.pair?.disabled : ''
        }}
        key={showModal?.pair?._id}
        onSubmit={async (values) => {
          const { symbol, lotSize, minNotional, disabled } = values;

          if (showModal.type == 'add') {
            await dispatch(addPair(
              {
                symbol: symbol,
                lotSize: lotSize,
                minNotional: minNotional,
                disabled: disabled ? disabled : false,
              }));
          } else if (showModal.type == 'edit') {
            await dispatch(editPair({
              symbol: symbol ? symbol : setShowModal?.pair?.symbol,
              lotSize: lotSize ? lotSize : showModal?.pair?.lotSize,
              minNotional: minNotional ? minNotional : showModal?.pair?.minNotional,
              disabled: disabled ? disabled : false,
              id: showModal.pair._id,
            }));
          } else {
            await dispatch(removePair(showModal.pair._id));
          }

          setShowModal({ visible: false, type: '', pair: {} });
          await dispatch(fetchPairs());
        }}>
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          initialValues,
        }) => (
          <Form onSubmit={handleSubmit}>
            {showModal?.type == 'remove' && <Contain justify={'center'}><P fontSize={30}>{showModal.pair.symbol}</P></Contain>}
            {showModal.type !== 'remove' && (
              <>
                <Input
                  type='text'
                  name='symbol'
                  placeholder='Symbol'
                  onChange={handleChange}
                  defaultValue={showModal.type == 'edit' ? showModal.pair.symbol : values.symbol}
                  height='50px'
                  margin='45px 0 20px'
                  {...{
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    initialValues,
                    values,
                  }}
                />
                <Input
                  type='number'
                  name='lotSize'
                  placeholder='Lot size'
                  onChange={handleChange}
                  defaultValue={showModal.type == 'edit' ? showModal.pair.lotSize : values.lotSize}
                  height='50px'
                  margin='45px 0 20px'
                  {...{
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    initialValues,
                    values,
                  }}
                />
                <Input
                  type='number'
                  name='minNotional'
                  placeholder='minNotional'
                  onChange={handleChange}
                  defaultValue={showModal.type == 'edit' ? showModal.pair.minNotional : values.minNotional}
                  height='50px'
                  margin='45px 0 20px'
                  {...{
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    initialValues,
                    values,
                  }}
                />
                <Contain direction={'column'} align={'flex-start'} margin={'20px 0 20px 0'}>
                  <label>Disabled:</label>
                  <Contain margin={'5px 0 0'}>
                    <Field
                      type='checkbox'
                      name='disabled'
                      {...{
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        initialValues,
                        values,
                      }}
                      checked={values.disabled}
                    />
                  </Contain>
                </Contain>
              </>
            )}
            <Button type='submit' width='100%' fontSize={16} margin='40px 0 '>{showModal.type}</Button>
          </Form>
        )}
      </Formik>
    </Rodal >
  );
};

PairModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default PairModal;