import styled from '@emotion/styled';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});

export const FormContainer = styled.div({
  maxWidth: '385px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  img: {
    height: '70px',
  }
});
