import styled from '@emotion/styled';

const Container = styled.section({
  position: 'relative',
  width: '100%',
  maxWidth: '1470px',
  margin: '0 auto',
  padding: '0 30px',
});

export default Container;